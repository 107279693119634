import sliderMainImg1 from './slider-main-img-1.jpg'
import sliderMainImg2 from './slider-main-img-2.jpg'
import sliderMainImg3 from './slider-main-img-3.jpg'
import sliderMainImg4 from './slider-main-img-4.jpg'

export const sliderMainImagesArray = [
  sliderMainImg1,
  sliderMainImg2,
  sliderMainImg3,
  sliderMainImg4
]
