import React from 'react'

const PlanningIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='65' height='65' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M54.776 55.358l.866-1.731-6.282-35.574c.625-.976.992-2.134.992-3.376 0-2.322-1.269-4.349-3.147-5.439V0h-6.294v9.238c-1.877 1.09-3.147 3.117-3.147 5.439 0 1.242.367 2.4.993 3.376l-3.197 18.1L0 .614V65h64.424l-9.648-9.642zm-1.32-2.051l-1.006 2.01-1.094-2.187-6.057-32.287c.822-.165 1.585-.49 2.258-.941l5.899 33.405zm-5.001-4.268l-7.404-7.4 3.008-16.031 4.396 23.431zM43.009 2.097h2.098v6.384c-.341-.057-.691-.094-1.048-.094-.358 0-.708.037-1.05.094V2.097zm1.05 8.387c2.314 0 4.196 1.88 4.196 4.193 0 2.313-1.882 4.194-4.196 4.194-2.315 0-4.197-1.881-4.197-4.194 0-2.313 1.882-4.193 4.197-4.193zm-3.499 9.419c.672.451 1.436.776 2.258.941l-6.057 32.287-1.095 2.186-1.005-2.009 5.899-33.405zm9.792 39.855v3.145h-4.195v-5.242h-2.098v5.242h-4.197v-3.145h-2.098v3.145h-4.195v-5.242h-2.098v5.242h-4.197v-3.145h-2.098v3.145H20.98v-5.242h-2.098v5.242h-4.196v-3.145h-2.098v3.145H8.392v-5.242H6.294v5.242H2.098v-5.242h3.147v-2.097H2.098v-4.193h5.245v-2.097H2.098V45.08h3.147v-2.097H2.098V38.79h5.245v-2.097H2.098V32.5h3.147v-2.097H2.098v-4.194h5.245v-2.096H2.098v-4.194h3.147v-2.097H2.098v-4.193h5.245v-2.097H2.098V5.676l33.018 32.997-1.781 10.083L10.49 25.924v28.592h16.784v-2.097H12.588V30.986l20.303 20.29-.203 1.143h-3.315v2.097h3.547l2.746 5.489 3.102-6.2 1.814-9.668 8.557 8.552.182.971 3.008 6.098h-1.977zm2.098.247l1.337-2.671 5.572 5.569H52.45v-2.898z'
      />
    </svg>
  )
}

export default PlanningIcon
