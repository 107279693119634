import styled, { keyframes } from 'styled-components'
import {
  colorCarouselBG,
  colorDark,
  oswaldBold,
  colorSliderGray,
  oswaldLight,
  modalSliderLg,
  modalSliderMd,
  breakPointMd
} from '../../../styled/helper.styled'

const fadeIn = keyframes`
  from {opacity: 0}
  to: {opacity: 1}
`

export const SlashSpan = styled.span`
  margin-right: 4px;
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: ${colorCarouselBG};
  z-index: 100;
  animation: ${fadeIn} 0.3s ease forwards;
  min-height: -webkit-fill-available;
`

export const ImageContainer = styled.div`
  max-width: 1376px;
  width: 100%;
  margin: 0px auto;
  height: 100%;
  transition: 0.2s ease;
  position: relative;
  img {
    &:nth-child(${({ currentSlide }) => currentSlide + 1}) {
      opacity: 1;
    }
  }
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.2s ease;
`

export const ControlsInfoWrapper = styled.div`
  height: 118px;
  @media only screen and (max-width: ${modalSliderLg}) {
    height: 235px;
  }
  @media only screen and (max-width: ${modalSliderMd}) {
    height: 305px;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  height: 118px;
  background: ${colorDark};
  padding: 0 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: ${modalSliderLg}) {
    flex-direction: column;
    height: 185px;
    justify-content: start;
    align-items: baseline;
    padding: 0 15px;
  }
  @media only screen and (max-width: ${modalSliderMd}) {
    height: 255px;
  }
`

export const Heading = styled.h2`
  ${oswaldBold};
  color: white;
  font-size: 34px;
  text-transform: uppercase;
  margin: 30px 0;
  @media only screen and (max-width: ${breakPointMd}) {
    font-size: 26px;
    margin: 20px 0;
  }
`

export const InfoList = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
`

export const InfoItem = styled.li`
  margin-left: 95px;
  @media only screen and (max-width: ${modalSliderLg}) {
    margin-left: 0;
    margin-right: 50px;
  }
  @media only screen and (max-width: ${modalSliderMd}) {
    margin-right: 0;
    width: 33.33%;
  }
`

export const InfoTitle = styled.h3`
  ${oswaldBold};
  color: white;
  font-size: 22px;
  @media only screen and (max-width: ${modalSliderLg}) {
    font-size: 18px;
  }
`
export const InfoText = styled.p`
  ${oswaldLight};
  color: ${colorSliderGray};
  font-size: 18px;
  @media only screen and (max-width: ${modalSliderLg}) {
    font-size: 16px;
  }
`

export const ControlsContainer = styled.div`
  width: 100%;
  padding: 14px 27px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  height: 50px;
  align-items: center;
  position: fixed;
  bottom: 118px;
  @media only screen and (max-width: ${modalSliderLg}) {
    padding: 14px 10px;
    position: static;
  }
  @media only screen and (max-width: ${modalSliderMd}) {
    bottom: 255px;
  }
`
export const ProjectsIndex = styled.div``
export const CurrentIndex = styled.span`
  color: white;
  ${oswaldBold};
  margin-right: 7px;
`
export const ProjectsCount = styled.span`
  ${oswaldLight};
  color: ${colorSliderGray};
`
