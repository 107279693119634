const useSmoothScroll = () => {
  const scrollTo = (e, id, extraOffset) => {
    e.preventDefault()
    const positionY =
      id === 'top' ? 0 : document.getElementById(id).offsetTop - extraOffset
    window.scrollTo({ top: positionY, behavior: 'smooth' })
  }
  return [scrollTo]
}
export default useSmoothScroll
