import React from 'react'

const PlusIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='23' height='23' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M23 10.952H12.048V0h-1.096v10.952H0v1.096h10.952V23h1.096V12.048H23v-1.096z'
      />
    </svg>
  )
}

export default PlusIcon
