import styled, { css } from 'styled-components'
import { colorDark } from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
`
export const SocialLink = styled.a`
  outline: none;
  &:nth-child(2) {
    margin: 0 10px;
  }
  color: ${colorDark};
`
