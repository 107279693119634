import React from 'react'

const DesigningIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='66' height='65' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M8.791 46.583H6.617v-2.166h2.174v2.166zm0-4.334H6.617v-2.166h2.174v2.166zm0-4.333H6.617v-2.167h2.174v2.167zm0-4.333H6.617v-2.167h2.174v2.167zm0-4.333H6.617v-2.167h2.174v2.167zm0-4.334H6.617V22.75h2.174v2.166zm0-4.333H6.617v-2.167h2.174v2.167zm0-4.333H6.617v-2.167h2.174v2.167zM6.617 5.417h2.174v2.166H6.617V5.417zm2.174 6.499H6.617V9.75h2.174v2.166zm56.414 28.492v.108c-.108.109-.108.217-.217.325L53.031 52.758c-.217.217-.435.325-.76.325H27.269v-2.166h23.914V40.083c0-.65.435-1.084 1.088-1.084H63.14V2.167H2.269v48.75h9.783v2.166H1.182C.53 53.083.095 52.65.095 52V1.083C.095.433.53 0 1.182 0h63.045c.653 0 1.087.433 1.087 1.083v39c0 .108 0 .217-.109.325zm-11.848.758V49.4l4.131-4.117 4.131-4.117h-8.262zM10.965 5.417h2.174v2.166h-2.174V5.417zm2.174 6.499h-2.174V9.75h2.174v2.166zm4.348-4.333h-2.174V5.417h2.174v2.166zm1.196 2.925c.108-.433.543-.758.978-.758.435 0 .869.325.978.758l3.152 9.425c.109.108.218.433.218.65v43.333c0 .65-.435 1.084-1.087 1.084H16.4c-.652 0-1.087-.434-1.087-1.084V20.583c0-.217.109-.433.217-.65l3.153-9.425zm.978 3.792l-1.74 5.2H21.4l-1.739-5.2zm2.174 7.366h-1.088V55.25h1.088V21.666zm-4.348 41.167h4.348v-5.417h-4.348v5.417zm0-7.583h1.087V21.666h-1.087V55.25zm10.869-36.834V16.25c0-5.417 4.349-9.75 9.783-9.75 5.435 0 9.783 4.333 9.783 9.75v2.166h-2.173V16.25c0-4.225-3.37-7.584-7.61-7.584-4.239 0-7.609 3.359-7.609 7.584v2.166h-2.174zm30.436-3.25v2.167c0 5.417-4.348 9.75-9.783 9.75s-9.783-4.333-9.783-9.75v-2.167h2.175v2.167c0 4.225 3.369 7.583 7.608 7.583 4.24 0 7.609-3.358 7.609-7.583v-2.167h2.174zm-21.74 16.25V32.5h-2.174v-2.167c0-.65.435-1.083 1.088-1.083h8.696c.652 0 1.087.433 1.087 1.083v8.666c0 .651-.435 1.084-1.087 1.084h-2.175v-2.167h1.087v-6.5h-6.522zm-8.696 3.25h10.87c.653 0 1.087.434 1.087 1.083v10.834c0 .65-.434 1.084-1.087 1.084h-10.87c-.652 0-1.087-.434-1.087-1.084V35.749c0-.649.435-1.083 1.087-1.083zM29.444 45.5h8.695v-8.667h-8.695V45.5z'
      />
    </svg>
  )
}

export default DesigningIcon
