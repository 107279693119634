import React from 'react'
import { Fade } from './style'
import { Transition } from 'react-transition-group'

const WithFadeOut = WrappedComponent => ({ isOpen }) => {
  return (
    <Transition in={isOpen} timeout={500} unmountOnExit mountOnEnter>
      {state => (
        <Fade state={state}>
          <WrappedComponent />
        </Fade>
      )}
    </Transition>
  )
}

export default WithFadeOut
