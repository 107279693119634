import React from 'react'
import { Wrapper, SectionHeadingText } from './style'

const SectionHeading = ({ children }) => {
  return (
    <Wrapper>
      <SectionHeadingText> {children} </SectionHeadingText>
    </Wrapper>
  )
}

export default SectionHeading
