import { css, createGlobalStyle } from 'styled-components'
import OswaldExtraLight from '../assets/fonts/Oswald-ExtraLight.ttf'
import OswaldBold from '../assets/fonts/Oswald-Bold.ttf'
import RobotoLight from '../assets/fonts/Roboto-Light.ttf'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 }

  body {
  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      overflow: hidden;
    `}
  }

  #root {
  overflow-x: hidden;
  }

  @font-face {
    font-family: 'Oswald-ExtraLight';
    src: local('Oswald-ExtraLight'), url(${OswaldExtraLight}) format('truetype');
  }

  @font-face {
    font-family: 'Oswald-Bold';
    src: local('Oswald-Bold'), url(${OswaldBold}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto-Light';
    src: local('Roboto-Light'), url(${RobotoLight}) format('truetype');
  }
}
`

// FONTS
const fonts = {
  oswaldBold: css`
    font-family: Oswald-Bold;
  `,
  oswaldLight: css`
    font-family: Oswald-ExtraLight;
  `,
  robotoLight: css`
    font-family: Roboto-Light;
  `
}
export const { oswaldBold, oswaldLight, robotoLight } = fonts

// COLORS
const colors = {
  colorPrimary: '#ffcc09',
  colorDark: '#3d3838',
  colorLight: '#4c4c4c',
  colorExtraLight: '#8e8e8e',
  colorGrayBackground: '#f4f4f4',
  colorCarouselBG: '#312d2d',
  colorSliderGray: '#b4b4b4',
  aboutUsIconsColor: '#252999',
  aboutUsColorGray: '#868585'
}
export const {
  colorPrimary,
  colorDark,
  colorLight,
  colorExtraLight,
  colorGrayBackground,
  colorCarouselBG,
  colorSliderGray,
  aboutUsIconsColor,
  aboutUsColorGray
} = colors

const breakPoints = {
  containerBreakPoint: '1200px',
  containerTabletBreakPoint: '800px',

  breakPointMd: '560px',
  breakPointSm: '470px',

  modalSliderLg: '1650px',
  modalSliderMd: '720px',
  modalSliderSm: '330px',

  breakPointHeightMd: '800px',
  breakPointHeightSm: '640px',

  sliderMd: '680px',
  sliderSm: '380px',

  modalContactsLg: '1500px',
  modalContactsMd: '700px',
  modalContactsSm: '470px',

  modalContactsHeightLg1: '960px',
  modalContactsHeightLg2: '920px',

  modalContactsHeightMd1: '820px',
  modalContactsHeightMd2: '800px',
  modalContactsHeightMd3: '700px',

  modalContactsHeightSm1: '680px',
  modalContactsHeightSm2: '650px',
  modalContactsHeightSm3: '560px',
  modalContactsHeightSm4: '480px',

  portfolioTableBreakPointLg: '1050px',
  portfolioTableBreakPointMd: '746px',
  headerModalBreakPointMd: '580px'
}

export const {
  containerBreakPoint,
  modalSliderLg,
  modalSliderMd,
  modalSliderSm,
  breakPointHeightMd,
  breakPointHeightSm,
  sliderMd,
  sliderSm,
  breakPointMd,
  breakPointSm,
  modalContactsLg,
  modalContactsMd,
  modalContactsSm,
  modalContactsHeightLg1,
  modalContactsHeightLg2,
  modalContactsHeightMd1,
  modalContactsHeightMd2,
  modalContactsHeightMd3,
  modalContactsHeightSm1,
  modalContactsHeightSm2,
  modalContactsHeightSm3,
  modalContactsHeightSm4,
  containerTabletBreakPoint,
  portfolioTableBreakPointLg,
  portfolioTableBreakPointMd,
  headerModalBreakPointMd
} = breakPoints

// LAYOUT
const layout = {
  container: css`
    width: 1140px;
    margin: auto;
    transition: 0.2s ease;
    @media only screen and (max-width: ${containerBreakPoint}) {
      width: 100%;
      padding: 0 20px;
    }
    @media only screen and (max-width: ${breakPointSm}) {
      padding: 0 15px;
    }
  `,
  containerTablet: css`
    @media only screen and (max-width: ${containerBreakPoint}) {
      width: 767px;
      padding: 0;
      margin: auto;
    }
    @media only screen and (max-width: ${containerTabletBreakPoint}) {
      width: inherit;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
    }
  `
}
export const { container, containerTablet } = layout
