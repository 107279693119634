import React from 'react'
import { Wrapper, SocialLink } from './style'
import FacebookIcon from '../../svg/FacebookIcon'
import TwitterIcon from '../../svg/TwitterIcon'
import LinkedInIcon from '../../svg/LinkedInIcon'

const Socials = ({ center }) => {
  return (
    <Wrapper center={center}>
      <SocialLink href='/#' aria-label='Facebook Icon'>
        <FacebookIcon />
      </SocialLink>
      <SocialLink href='/#' aria-label='Twitter Icon'>
        <TwitterIcon />
      </SocialLink>
      <SocialLink href='/#' aria-label='Linked In Icon'>
        <LinkedInIcon />
      </SocialLink>
    </Wrapper>
  )
}

export default Socials
