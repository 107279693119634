import styled from 'styled-components'
import {
  container,
  colorLight,
  robotoLight,
  oswaldBold,
  aboutUsColorGray,
  containerBreakPoint,
  breakPointSm,
  containerTablet
} from '../../../styled/helper.styled'
import { aboutUsIconsColor } from '../../../styled/helper.styled'
import PlanningIcon from '../../svg/PlanningIcon'
import DesigningIcon from '../../svg/DesigningIcon'
import ThreeDIcon from '../../svg/ThreeDIcon'

export const StyledPlanningIcon = styled(PlanningIcon)`
  color: ${aboutUsIconsColor};
`
export const StyledDesigningIcon = styled(DesigningIcon)`
  color: ${aboutUsIconsColor};
`
export const StyledThreeDIcon = styled(ThreeDIcon)`
  color: ${aboutUsIconsColor};
`

export const AboutUsWrapper = styled.div`
  display: flex;
  position: relative;
  height: 636px;

  @media only screen and (max-width: ${containerBreakPoint}) {
    flex-direction: column;
    height: auto;
    margin-top: 40px;
  }
`
export const Container = styled.div`
  ${container};
  ${containerTablet};
  @media only screen and (max-width: ${containerBreakPoint}) {
    padding-top: 50px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    padding-top: 20px;
  }
`

export const ContentConainer = styled.div`
  width: 50%;
  padding-top: 200px;
  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 100%;
    padding-top: 0;
    & div:nth-child(4) {
      text-align: center;
    }
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 1.43;
  ${robotoLight}
  margin-top: 30px;
  margin-bottom: 40px;
  color: ${colorLight};
  @media only screen and (max-width: ${containerBreakPoint}) {
    text-align: left;
    margin-bottom: 60px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    text-align: center;
    margin-bottom: 30px;
  }
`

export const IconBoxesContainer = styled.div`
  display: flex;
  margin-bottom: 56px;
  @media only screen and (max-width: ${containerBreakPoint}) {
    margin-bottom: 70px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    flex-direction: column;
    max-width: 315px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
`

export const IconBox = styled.div`
  flex: 1;
  text-align: center;
  &:nth-child(2) {
    margin: 0 47px;
  }

  @media only screen and (max-width: ${breakPointSm}) {
    margin-bottom: 36px;
    flex: none;
    &:nth-child(2) {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 36px;
    }
  }
`

export const IconBoxTitle = styled.h3`
  ${oswaldBold}
  font-size: 20px;
  margin-top: 13px;
  margin-bottom: 7px;
`

export const IconBoxText = styled.p`
  ${robotoLight}
  line-height: 1.33;
  font-size: 12px;
  color: ${aboutUsColorGray};
`
export const ImageContainer = styled.div`
  text-align: right;
  position: absolute;
  right: 0;
  top: 100px;
  width: 50%;
  height: 100%;

  @media only screen and (max-width: ${containerBreakPoint}) {
    position: static;
    width: 100%;
    height: 400px;
    margin-top: 50px;
  }
`

export const Image = styled.div`
  width: 90%;
  height: 100%;
  display: inline-block;
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  position: relative;
  right: 0;

  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 100%;
  }
`
