import styled, { css } from 'styled-components'
import {
  container,
  oswaldBold,
  colorDark,
  oswaldLight,
  colorSliderGray,
  sliderSm,
  containerBreakPoint,
  containerTabletBreakPoint,
  breakPointHeightSm
} from '../../../styled/helper.styled'

export const SliderWrapper = styled.div``

export const ImageContainer = styled.div`
  background: ${colorDark};
  position: relative;
  height: 692px;
  ${({ latestProject }) =>
    latestProject &&
    css`
      height: 640px;
    `}
  div {
    &:nth-child(${({ currentSlide }) => currentSlide + 1}) {
      opacity: 1;
    }
  }
  @media screen and (max-width: ${containerBreakPoint}) {
    height: 425px;
    ${({ latestProject }) =>
      latestProject &&
      css`
        height: 576px;
      `}
  }
  @media screen and (max-width: ${breakPointHeightSm}) {
    ${({ latestProject }) =>
      latestProject &&
      css`
        height: 211px;
      `}
  }
`

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ img }) => img});
  background-position: center;
  background-size: cover;
  ${({ fullWidth }) => (fullWidth ? '' : container)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s ease;
  opacity: 0;
`

export const Caption = styled.div`
  ${container};
  bottom: 85px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s ease;
  @media only screen and (max-width: ${containerBreakPoint}) {
    bottom: 50px;
    margin-left: 20px;
  }
  @media only screen and (max-width: ${containerTabletBreakPoint}) {
    margin-left: 0;
  }
`

export const Heading = styled.h1`
  background: white;
  color: black;
  ${oswaldBold};
  font-size: 44px;
  text-transform: uppercase;
  color: ${colorDark};
  padding: 0 15px;
  display: inline-block;
  transition: 0.2s ease;
  &:nth-child(1) {
    margin-bottom: 10px;
  }
  @media only screen and (max-width: ${containerTabletBreakPoint}) {
    font-size: 5.4vw;
  }
`

export const Controls = styled.div`
  ${container};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media only screen and (max-width: ${sliderSm}) {
    justify-content: flex-start;
  }
`

export const SliderIndexes = styled.ul`
  display: flex;
  list-style-type: none;
  @media only screen and (max-width: ${sliderSm}) {
    margin-bottom: 20px;
    display: none;
  }
`

export const Index = styled.li`
  ${({ active }) =>
    active
      ? `${oswaldBold}; color: ${colorDark}`
      : `${oswaldLight}; color: ${colorSliderGray}`};
  margin-right: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`
