import React from 'react'

const Facebook = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M9.797.748C4.396.748.002 5.065.002 10.372s4.394 9.624 9.795 9.624 9.795-4.317 9.795-9.624S15.198.748 9.797.748zm2.436 9.963h-1.594v5.582H8.278v-5.582H7.156V8.739h1.122V7.463c0-.914.442-2.342 2.383-2.342l1.749.007v1.914h-1.27c-.207 0-.5.102-.5.538v1.159h1.799l-.206 1.972z'
      />
    </svg>
  )
}

export default Facebook
