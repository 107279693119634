import styled, { css } from 'styled-components'
import { colorPrimary, oswaldBold } from '../../../styled/helper.styled'

export const ButtonWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
`

export const ButtonLink = styled.a`
  ${oswaldBold}
  outline: none;
  text-decoration: none;
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  border-radius: 33px;
  border: 1px solid ${colorPrimary};
  padding: 15px 40px;
  transition: 0.2s ease;
  &:hover {
    background: ${colorPrimary};
  }
`
