import styled from 'styled-components'
import {
  oswaldBold,
  oswaldLight,
  colorSliderGray,
  colorDark,
  sliderSm
} from '../../../styled/helper.styled'

const getColor = ({ active, theme }) => {
  let color
  if (theme === 'dark') {
    color = active ? colorDark : colorSliderGray
  } else {
    color = active ? `white` : colorSliderGray
  }
  return color
}

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${colorSliderGray};
`

export const Button = styled.div`
  ${({ active }) => (active ? oswaldBold : oswaldLight)};
  color: ${props => getColor(props)};
  margin: 0 19px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:nth-child(2) {
    margin-right: 0;
  }
  svg {
    margin: 0 8px;
  }
  @media only screen and (max-width: ${sliderSm}) {
    &:nth-child(1) {
      margin-left: 0;
    }
  }
`
