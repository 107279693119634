import React, { useContext } from 'react'
import aboutUsImage from '../../../assets/images/about-us-img.jpg'
import Button from '../../shared/Button/Button'
import SectionHeading from '../../shared/SectionHeading/SectionHeading'
import { ModalContactFormContext } from '../../../context/modalContactFormContext'
import {
  AboutUsWrapper,
  Container,
  ContentConainer,
  Text,
  IconBoxesContainer,
  IconBox,
  ImageContainer,
  IconBoxTitle,
  IconBoxText,
  Image,
  StyledPlanningIcon,
  StyledDesigningIcon,
  StyledThreeDIcon
} from './style'

function AboutUs () {
  const { openContactModal } = useContext(ModalContactFormContext)

  return (
    <AboutUsWrapper id='about-us'>
      <Container>
        <ContentConainer>
          <SectionHeading>About Us</SectionHeading>
          <Text>
            Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie
            malesuada. Vivamus suscipit tortor eget felis porttitor volutpat.
            Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Vivamus
            suscipit tortor eget felis porttitor volutpat. Nulla porttitor
            accumsan tincidunt. Some of our services:
          </Text>

          <IconBoxesContainer>
            <IconBox>
              <StyledPlanningIcon />
              <IconBoxTitle> Planning </IconBoxTitle>
              <IconBoxText>
                Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie
                malesuada.
              </IconBoxText>
            </IconBox>
            <IconBox>
              <StyledDesigningIcon />
              <IconBoxTitle> Designing </IconBoxTitle>
              <IconBoxText>
                Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie
                malesuada.
              </IconBoxText>
            </IconBox>
            <IconBox>
              <StyledThreeDIcon />
              <IconBoxTitle> Making in 3D </IconBoxTitle>
              <IconBoxText>
                Nulla porttitor accumsan tincidunt. Donec sollicitudin molestie
                malesuada.
              </IconBoxText>
            </IconBox>
          </IconBoxesContainer>

          <Button center={false} funcOnClick={openContactModal}>
            Contact us
          </Button>
        </ContentConainer>
      </Container>

      <ImageContainer>
        <Image image={aboutUsImage} />
      </ImageContainer>
    </AboutUsWrapper>
  )
}

export default AboutUs
