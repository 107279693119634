import styled, { css } from 'styled-components'
import { colorSliderGray, colorCarouselBG } from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
  top: 0;
  padding: 11px;
  opacity: 0.5;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: ${colorSliderGray};
  margin: 10px;
  ${({ isBackground }) =>
    isBackground &&
    css`
      background: ${colorCarouselBG};
    `};
`
