import { useState } from 'react'

const useModal = () => {
  const [isModalOpen, setModal] = useState(false)

  const onKeyPress = ({ keyCode }) => (keyCode === 27 ? closeModal() : null)

  const addEscFunc = () =>
    document.addEventListener('keydown', onKeyPress, false)
  const removeEscFunc = () =>
    document.removeEventListener('keydown', onKeyPress, false)

  const openModal = () => setModal(true)
  const closeModal = () => setModal(false)

  return { isModalOpen, openModal, closeModal, addEscFunc, removeEscFunc }
}

export default useModal
