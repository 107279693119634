import React, { useContext, useEffect } from 'react'
import { ModalContactFormContext } from '../../context/modalContactFormContext'
import { ModalSliderContext } from '../../context/modalSliderContext'
import WithFadeAnimation from '../shared/WithFadeAnimation/WithFadeAnimation'
import ModalContactForm from '../shared/ModalContactForm/ModalContactForm'
import ModalSlider from '../shared/ModalSlider/ModalSlider'
import Header from '../shared/Header/Header'
import MainPage from '../pages/MainPage/MainPage'
import Footer from '../shared/Footer/Footer'
import { GlobalStyle } from '../../styled/helper.styled'
import useHeaderAnimation from '../hooks/useHeaderAnimation'

const ModalContactFormWithFadeAnimation = WithFadeAnimation(ModalContactForm)
const ModalSliderWithFadeAnimation = WithFadeAnimation(ModalSlider)

const Root = () => {
  const { isModalSliderOpen } = useContext(ModalSliderContext)
  const { isContactModalOpen } = useContext(ModalContactFormContext)

  const [addScrollEvenet, removeScrollEvent, showHeader] = useHeaderAnimation()
  useEffect(() => {
    addScrollEvenet()
    return removeScrollEvent()
  }, [addScrollEvenet, removeScrollEvent])

  return (
    <>
      <GlobalStyle isModalOpen={isContactModalOpen || isModalSliderOpen} />
      <Header />
      <Header showHeader={showHeader} isFixed />
      <MainPage />
      <Footer />

      <ModalContactFormWithFadeAnimation isOpen={isContactModalOpen} />
      <ModalSliderWithFadeAnimation isOpen={isModalSliderOpen} />
    </>
  )
}

export default Root
