import React from 'react'
import { Wrapper, ContactsItem, Link } from './style'
import PhoneIcon from '../../svg/PhoneIcon'
import EnvelopeIcon from '../../svg/EnvelopeIcon'

const Contacts = ({ center }) => {
  return (
    <Wrapper center={center}>
      <ContactsItem>
        <Link href='mailto:info@artecstudio.us'>
          <EnvelopeIcon />
          info@artecstudio.us
        </Link>
      </ContactsItem>
      <ContactsItem>
        <Link href='tel:+13477675938'>
          <PhoneIcon /> +1 (347) 767-5938
        </Link>
      </ContactsItem>
    </Wrapper>
  )
}

export default Contacts
