export const projectCategories = Object.freeze({
  ALL: 'All'
})

export const portfolioTableConsts = Object.freeze({
  PROJECTS_TO_SHOW: 9
})

export const HeaderHeight = Object.freeze({
  desktop: 111,
  mobile: 80
})
