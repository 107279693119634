import React from 'react'
import Root from './components/Root/Root'
import AppContext from './components/AppContext/AppContext'

function App () {
  return (
    <AppContext>
      <Root />
    </AppContext>
  )
}

export default App
