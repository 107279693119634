import {
  ProjectsImagesArray,
  ProjectsImagesThumbArray
} from '../assets/images/desktop/projects/projectsImages'

const projects = Object.freeze([
  {
    id: 0,
    image: ProjectsImagesArray[0],
    category: 'Public',
    title: 'Nisi quis eleifend quam',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2013',
    size: '46,000 sq/m',
    client: 'White House Group',
    thumb: ProjectsImagesThumbArray[0]
  },
  {
    id: 1,
    image: ProjectsImagesArray[1],
    category: 'Offices',
    title: 'Bibendum neque egestas congue',
    location: 'Sofia, Bulgaria',
    status: 'Build in 2012',
    size: '26,000 sq/m',
    client: 'House Group',
    thumb: ProjectsImagesThumbArray[1]
  },
  {
    id: 2,
    image: ProjectsImagesArray[2],
    category: 'Houses',
    title: 'Curabitur vitae nunc sed',
    location: 'Sofia, Bulgaria',
    status: 'Build in 2014',
    size: '56,000 sq/m',
    client: 'Black Group',
    thumb: ProjectsImagesThumbArray[2]
  },
  {
    id: 3,
    image: ProjectsImagesArray[3],
    category: 'Public',
    title: 'Ultrices sagittis orci a',
    location: 'Varna, Bulgaria',
    status: 'Build in 2013',
    size: '26,000 sq/m',
    client: 'House Group',
    thumb: ProjectsImagesThumbArray[3]
  },
  {
    id: 4,
    image: ProjectsImagesArray[4],
    category: 'Hotels',
    title: 'Tortor condimentum lacinia quis',
    location: 'Sofia, Bulgaria',
    status: 'Build in 2015',
    size: '26,000 sq/m',
    client: 'Black Group',
    thumb: ProjectsImagesThumbArray[4]
  },
  {
    id: 5,
    image: ProjectsImagesArray[5],
    category: 'Interior',
    title: 'Odio facilisis mauris sit',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2011',
    size: '26,000 sq/m',
    client: 'White House Group',
    thumb: ProjectsImagesThumbArray[5]
  },
  {
    id: 6,
    image: ProjectsImagesArray[6],
    category: 'Interior',
    title: 'magna sit amet purus',
    location: 'Varna, Bulgaria',
    status: 'Build in 2012',
    size: '56,000 sq/m',
    client: 'Black House Group',
    thumb: ProjectsImagesThumbArray[6]
  },
  {
    id: 7,
    image: ProjectsImagesArray[7],
    category: 'Hotels',
    title: 'A lacus vestibulum sed',
    location: 'Varna, Bulgaria',
    status: 'Build in 2012',
    size: '36,000 sq/m',
    client: 'House Group',
    thumb: ProjectsImagesThumbArray[7]
  },
  {
    id: 8,
    image: ProjectsImagesArray[8],
    category: 'Hotels',
    title: 'Quis vel eros donec',
    location: 'Varna, Bulgaria',
    status: 'Build in 2015',
    size: '46,000 sq/m',
    client: 'Black House Group',
    thumb: ProjectsImagesThumbArray[8]
  },
  {
    id: 9,
    image: ProjectsImagesArray[9],
    category: 'Public',
    title: 'In fermentum posuere urna',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2016',
    size: '46,000 sq/m',
    client: 'White Group',
    thumb: ProjectsImagesThumbArray[9]
  },
  {
    id: 10,
    image: ProjectsImagesArray[10],
    category: 'Industrial',
    title: 'Et molestie ac feugiat',
    location: 'Plovdiv, Bulgaria',
    status: 'Build in 2012',
    size: '56,000 sq/m',
    client: 'Build Group',
    thumb: ProjectsImagesThumbArray[10]
  },
  {
    id: 11,
    image: ProjectsImagesArray[11],
    category: 'Hotels',
    title: 'Morbi quis commodo odio',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2014',
    size: '16,000 sq/m',
    client: 'Black Group',
    thumb: ProjectsImagesThumbArray[11]
  },
  {
    id: 12,
    image: ProjectsImagesArray[12],
    category: 'Offices',
    title: 'Interdum posuere lorem ipsum',
    location: 'Varna, Bulgaria',
    status: 'Build in 2013',
    size: '36,000 sq/m',
    client: 'White Group',
    thumb: ProjectsImagesThumbArray[12]
  },
  {
    id: 13,
    image: ProjectsImagesArray[13],
    category: 'Public',
    title: 'Nunc aliquet bibendum enim',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2011',
    size: '26,000 sq/m',
    client: 'White Group',
    thumb: ProjectsImagesThumbArray[13]
  },
  {
    id: 14,
    image: ProjectsImagesArray[14],
    category: 'Interior',
    title: 'Lobortis elementum nibh tellus',
    location: 'Plovdiv, Bulgaria',
    status: 'Build in 2011',
    size: '46,000 sq/m',
    client: 'Build Group',
    thumb: ProjectsImagesThumbArray[14]
  }
])

export default projects
