import React from 'react'

const ArrowLeftIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='10' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M4.937 0l.802.812-3.568 3.614H18v1.148H2.171l3.568 3.614-.802.812L0 5l4.937-5z'
      />
    </svg>
  )
}

export default ArrowLeftIcon
