import React, { useContext } from 'react'
import SingleProject from '../SingleProject/SingleProject'
import {
  PortfolioTableWrapper,
  PortfolioList,
  PorfolioItem,
  MenuCategories,
  Category,
  Container
} from './style'
import Button from '../Button/Button'
import Divider from '../Divider/Divider'
import { ModalSliderContext } from '../../../context/modalSliderContext'

const PortfolioTable = ({ show, setShow }) => {
  const {
    setCurrentSlide,
    openModalSlider,
    activeCategory,
    setActiveCategory,
    filteredProjects,
    categories,
    setSlides,
    filteredGallery
  } = useContext(ModalSliderContext)

  const onShowMore = () => {
    setShow(prev => prev + prev)
  }

  const onPortfolioClick = index => {
    setCurrentSlide(index)
    openModalSlider()
  }

  const isMiddle = index => index % 3 === 1

  const onPortfolioItemClick = index => {
    onPortfolioClick(index)
    setSlides(filteredGallery())
  }

  return (
    <PortfolioTableWrapper>
      <Container>
        <MenuCategories>
          {categories.map((category, index) => (
            <Category
              key={index}
              active={category === activeCategory}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </Category>
          ))}
        </MenuCategories>
      </Container>

      <Divider />

      <PortfolioList>
        {filteredProjects()
          .slice(0, show)
          .map((project, index) => (
            <PorfolioItem
              key={index}
              onClick={() => onPortfolioItemClick(index)}
              isMiddle={isMiddle(index)}
            >
              <SingleProject project={project} />
            </PorfolioItem>
          ))}
      </PortfolioList>

      {filteredProjects().slice(0, show).length < filteredProjects().length ? (
        <Button funcOnClick={onShowMore} center={true}>
          View more projects
        </Button>
      ) : null}
    </PortfolioTableWrapper>
  )
}

export default PortfolioTable
