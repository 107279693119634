import styled, { css } from 'styled-components'
import {
  colorDark,
  oswaldLight,
  colorPrimary,
  oswaldBold,
  containerBreakPoint
} from '../../../styled/helper.styled'

const transition = css`
  transition: 0.2s ease;
`

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 100%;
  background: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:hover div {
    opacity: 0.95;
  }
`
export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: ${colorDark};
  opacity: 0;
  ${transition}
  @media only screen and (max-width: ${containerBreakPoint}) {
    opacity: 0.95;
    height: 80px;
    bottom: 0;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  color: ${colorPrimary};
  opacity: 0;
  ${transition}
  @media only screen and (max-width: ${containerBreakPoint}) {
    display: none;
  }
`

export const Caption = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 0 25px;
  opacity: 0;
  ${transition}
  @media only screen and (max-width: ${containerBreakPoint}) {
    opacity: 0.95;
  }
`

export const Paragraph = styled.p`
  ${oswaldLight};
  color: white;
  font-size: 14px;
  margin-bottom: 15px;
  text-transform: uppercase;
  @media only screen and (max-width: ${containerBreakPoint}) {
    display: none;
  }
`

export const Heading = styled.h3`
  color: ${colorPrimary};
  line-height: 22px;
  font-size: 22px;
  ${oswaldBold}
  @media only screen and (max-width: ${containerBreakPoint}) {
    font-size: 18px;
    line-height: 19px;
  }
  
`
