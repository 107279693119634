import styled from 'styled-components'
import {
  container,
  containerTablet,
  breakPointHeightSm
} from '../../../styled/helper.styled'

export const PortfolioWrapper = styled.div`
  margin: 100px 0;
  @media screen and (max-width: ${breakPointHeightSm}) {
    margin-top: 70px;
    margin-bottom: 90px;
  }
`
export const Container = styled.div`
  ${container};
  &:nth-child(1) {
    ${containerTablet};
  }
`
