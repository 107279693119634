import React from 'react'
import { Wrapper } from './style'
import CloseXIcon from '../../svg/CloseXIcon'

const CloseIcon = ({ closeFunc, isBackground }) => {
  return (
    <Wrapper isBackground={isBackground} onClick={closeFunc}>
      <CloseXIcon />
    </Wrapper>
  )
}

export default CloseIcon
