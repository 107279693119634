import React from 'react'

const ArrowIcon = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='10' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M13.063 0l-.802.812 3.568 3.613H0v1.149h15.829l-3.568 3.614.802.812L18 5l-4.937-5z'
      />
    </svg>
  )
}

export default ArrowIcon
