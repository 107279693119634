import React, { useContext, useEffect } from 'react'
import {
  Wrapper,
  ImageContainer,
  Image,
  InfoContainer,
  Heading,
  InfoList,
  InfoItem,
  InfoTitle,
  InfoText,
  ControlsContainer,
  ProjectsIndex,
  CurrentIndex,
  ProjectsCount,
  SlashSpan,
  ControlsInfoWrapper
} from './style'
import { ModalSliderContext } from '../../../context/modalSliderContext'
import PrevNextBtns from '../PrevNextBtns/PrevNextBtns'
import CloseIcon from '../CloseIcon/CloseIcon'
const ModalSlider = () => {
  const {
    slides,
    currentSlide,
    nextSlide,
    prevSlide,
    closeModalSlider,
    isEnd,
    isStart,
    mouseDown,
    mouseUp,
    addEscFuncModalSlider,
    removeEscFuncModalSlider,
    onArrowKeyDown,
    removeOnArrowKeyDown,
    filteredProjects
  } = useContext(ModalSliderContext)

  const {
    location,
    status,
    size,
    category,
    client,
    title
  } = filteredProjects()[currentSlide]

  useEffect(() => {
    onArrowKeyDown()
    addEscFuncModalSlider()
    return () => {
      removeOnArrowKeyDown()
      removeEscFuncModalSlider()
    }
  }, [
    onArrowKeyDown,
    removeOnArrowKeyDown,
    addEscFuncModalSlider,
    removeEscFuncModalSlider
  ])

  const displaImageIndexes = () => {
    let currentImage = currentSlide + 1
    const totalImages = slides.length
    // As it is in design, current image should have '0' in front if it (01)
    // if the current image index is less than 10 and total images also are less than 10
    if (totalImages > 9) {
      currentImage = currentImage < 10 ? '0' + currentImage : currentImage
    }

    return { currentImage, totalImages }
  }

  return (
    <Wrapper>
      <CloseIcon isBackground={true} closeFunc={closeModalSlider} />

      <ImageContainer
        currentSlide={currentSlide}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onTouchStart={mouseDown}
        onTouchEnd={mouseUp}
      >
        {slides.map((image, index) => (
          <Image
            src={image}
            key={index}
            onMouseDown={e => e.preventDefault()}
          />
        ))}
      </ImageContainer>

      <ControlsContainer>
        <ProjectsIndex>
          <CurrentIndex>{displaImageIndexes().currentImage}</CurrentIndex>
          <ProjectsCount>
            <SlashSpan>/</SlashSpan> {displaImageIndexes().totalImages}
          </ProjectsCount>
        </ProjectsIndex>
        <PrevNextBtns
          prev={prevSlide}
          next={nextSlide}
          isEnd={isEnd()}
          isStart={isStart()}
          theme='light'
        />
      </ControlsContainer>

      <ControlsInfoWrapper>
        <InfoContainer>
          <Heading>{title}</Heading>
          <InfoList>
            <InfoItem>
              <InfoTitle>Location</InfoTitle>
              <InfoText>{location}</InfoText>
            </InfoItem>
            <InfoItem>
              <InfoTitle>Status</InfoTitle>
              <InfoText>{status}</InfoText>
            </InfoItem>
            <InfoItem>
              <InfoTitle>Client</InfoTitle>
              <InfoText>{client}</InfoText>
            </InfoItem>
            <InfoItem>
              <InfoTitle>Type</InfoTitle>
              <InfoText>{category}</InfoText>
            </InfoItem>
            <InfoItem>
              <InfoTitle>Size</InfoTitle>
              <InfoText>{size}</InfoText>
            </InfoItem>
          </InfoList>
        </InfoContainer>
      </ControlsInfoWrapper>
    </Wrapper>
  )
}

export default ModalSlider
