import React from 'react'
import {
  Wrapper,
  Overlay,
  IconContainer,
  Caption,
  Paragraph,
  Heading
} from './style'
import PlusIcon from '../../svg/PlusIcon'

const SingleProject = ({ project }) => {
  return (
    <Wrapper image={project.thumb}>
      <Overlay />

      <IconContainer>
        <PlusIcon />
      </IconContainer>

      <Caption>
        <Paragraph>{project.category}</Paragraph>
        <Heading>{project.title}</Heading>
      </Caption>
    </Wrapper>
  )
}

export default SingleProject
