import React, { useState, useRef } from 'react'
import Contacts from '../Contacts/Contacts'
import {
  HeaderWrapper,
  Container,
  HeaderContacts,
  LogoContainer,
  TogglerContainer,
  MobileToggler,
  TogglerTopLine,
  TogglerMidLine,
  TogglerBotLine,
  NavigationContainer,
  Navigation,
  NavigationItem,
  NavigationLink
} from './style'
import useSmoothScroll from '../../hooks/useSmoothScroll'
import Logo from '../../svg/Logo'

const Header = ({ showHeader, isFixed }) => {
  const [mobileNavOpened, setMobileNav] = useState(false)
  const [scrollTo] = useSmoothScroll()

  // We got two headers (fixed for scrolling and header on top of the page)
  // To navigate correctly (smooth scroll), we need the header's height
  // We get the height of the fixed one by getting the whole header height
  // But this wont work for the other one since while its toggled (mobile) his height is bigger
  // For that we get the contacts header's height + the padding (10 for bot and top = 20)
  const headerElement = useRef(null)
  const headerContactsElement = useRef(null)
  const onMenuLinkClick = (e, id) => {
    let extraOffSet = isFixed
      ? headerContactsElement.current.offsetHeight + 20
      : headerElement.current.offsetHeight
    // +50 just to get some extra space, and the section not to be right after the nav
    scrollTo(e, id, extraOffSet + 50)
    setMobileNav(false)
  }

  return (
    <HeaderWrapper
      isFixed={isFixed}
      showHeader={showHeader}
      ref={headerElement}
    >
      <Container>
        <HeaderContacts ref={headerContactsElement}>
          <LogoContainer onClick={e => onMenuLinkClick(e, 'top')}>
            <Logo />
          </LogoContainer>

          <Contacts />

          <TogglerContainer>
            <MobileToggler
              onClick={() => setMobileNav(prevValue => !prevValue)}
            >
              <TogglerTopLine
                mobileNavOpened={mobileNavOpened}
              ></TogglerTopLine>
              <TogglerMidLine
                mobileNavOpened={mobileNavOpened}
              ></TogglerMidLine>
              <TogglerBotLine
                mobileNavOpened={mobileNavOpened}
              ></TogglerBotLine>
            </MobileToggler>
          </TogglerContainer>
        </HeaderContacts>

        <NavigationContainer mobileNavOpened={mobileNavOpened}>
          <Navigation>
            <NavigationItem>
              <NavigationLink
                href='/#'
                onClick={e => onMenuLinkClick(e, 'about-us')}
              >
                About us
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                href='/#'
                onClick={e => onMenuLinkClick(e, 'latest-projects')}
              >
                Latest Projects
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                href='/#'
                onClick={e => onMenuLinkClick(e, 'our-portfolio')}
              >
                Our Portfolio
              </NavigationLink>
            </NavigationItem>
            <NavigationItem>
              <NavigationLink
                href='/#'
                onClick={e => onMenuLinkClick(e, 'contact-us')}
              >
                Contact us
              </NavigationLink>
            </NavigationItem>
          </Navigation>
        </NavigationContainer>
      </Container>
    </HeaderWrapper>
  )
}
export default Header
