import React, { useState } from 'react'
import SectionHeading from '../../shared/SectionHeading/SectionHeading'
import { PortfolioWrapper, Container } from './style'
import PortfolioTable from '../../shared/ProtoflioTable/PortfolioTable'
import { portfolioTableConsts } from '../../../constants/constants'

function Portfolio () {
  const [show, setShow] = useState(portfolioTableConsts.PROJECTS_TO_SHOW)

  return (
    <PortfolioWrapper id='our-portfolio'>
      <Container>
        <SectionHeading>Our Portfolio</SectionHeading>
      </Container>

      <Container>
        <PortfolioTable show={show} setShow={setShow} />
      </Container>
    </PortfolioWrapper>
  )
}

export default Portfolio
