import useModal from './useModal'

const useModalContactForm = () => {
  const {
    isModalOpen,
    openModal,
    closeModal,
    addEscFunc,
    removeEscFunc
  } = useModal()

  return {
    isContactModalOpen: isModalOpen,
    openContactModal: openModal,
    closeContactModal: closeModal,
    addEscFuncModalContacts: addEscFunc,
    removeEscFuncModalContacts: removeEscFunc
  }
}

export default useModalContactForm
