import styled from 'styled-components'
import { containerBreakPoint } from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  width: 100%;
  height: 1px;
  background: #eeeeee;
  margin: 15px 0;
  @media only screen and (max-width: ${containerBreakPoint}) {
    position: relative;
    width: 500%;
    left: -50%;
  }
`
