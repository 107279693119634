import styled from 'styled-components'
import {
  container,
  containerBreakPoint,
  containerTablet,
  breakPointHeightSm
} from '../../../styled/helper.styled'

export const LatestProjectsWrapper = styled.div`
  margin-top: 140px;
  @media only screen and (max-width: ${containerBreakPoint}) {
    margin-top: 100px;
  }
  @media only screen and (max-width: ${breakPointHeightSm}) {
    margin-top: 70px;
  }
`
export const Container = styled.div`
  ${container};
  ${containerTablet};
  margin-bottom: 40px;
  @media only screen and (max-width: ${containerBreakPoint}) {
    div:nth-child(1) {
      margin-bottom: 30px;
    }
  }
  div:nth-child(2) {
    margin-top: 30px;
  }
`
