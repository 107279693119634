import React from 'react'
import AboutUs from '../../sections/AboutUs/AboutUs'
import LatestProjects from '../../sections/LatestProjects/LatestProjects'
import WorkTogether from '../../sections/WorkTogether/WorkTogether'
import Portfolio from '../../sections/Portfolio/Portfolio'
import Slider from '../../shared/Slider/Slider'
import { MainPageWrapper } from './style'
import { sliderMainImagesArray } from '../../../assets/images/slider-main/sliderMainImagesArray'

function MainPage () {
  return (
    <MainPageWrapper>
      <Slider gallery={sliderMainImagesArray} fullWidth={true} theme='dark' />
      <AboutUs />
      <LatestProjects />
      <Portfolio />
      <WorkTogether />
    </MainPageWrapper>
  )
}

export default MainPage
