import React, { useCallback, useEffect } from 'react'
import {
  Wrapper,
  Form,
  FormGroup,
  Label,
  Field,
  TextArea,
  ErrorMessage,
  Spinner
} from './style'
import Button from '../Button/Button'
import useForm from '../../hooks/useForm'

const ContactForm = () => {
  const [form, showSpinner] = useForm()
  const { handleSubmit } = form

  const enterKeyPress = useCallback(
    event => {
      if (event.keyCode === 13) {
        handleSubmit()
      }
    },
    [handleSubmit]
  )

  useEffect(() => {
    document.addEventListener('keydown', enterKeyPress, false)
    return () => {
      document.removeEventListener('keydown', enterKeyPress, false)
    }
  }, [enterKeyPress])

  return (
    <Wrapper>
      <Form>
        <FormGroup>
          <Field
            type='text'
            name='name'
            onChange={form.handleChange}
            value={form.values.name}
            onBlur={form.handleBlur}
          />
          <Label active={!!form.values.name}>
            Full Name
            <ErrorMessage>
              {form.touched.name && form.errors.name
                ? `* ${form.errors.name}`
                : null}
            </ErrorMessage>
          </Label>
        </FormGroup>

        <FormGroup>
          <Field
            type='email'
            name='email'
            onChange={form.handleChange}
            value={form.values.email}
            onBlur={form.handleBlur}
          />
          <Label active={!!form.values.email}>
            Email Address
            <ErrorMessage>
              {form.touched.email && form.errors.email
                ? `* ${form.errors.email}`
                : null}
            </ErrorMessage>
          </Label>
        </FormGroup>

        <FormGroup>
          <TextArea
            name='message'
            onChange={form.handleChange}
            value={form.values.message}
            onBlur={form.handleBlur}
          />
          <Label active={!!form.values.message}>
            Message
            <ErrorMessage>
              {form.touched.message && form.errors.message
                ? `* ${form.errors.message}`
                : null}
            </ErrorMessage>
          </Label>
        </FormGroup>

        <FormGroup>
          <Button center={true} funcOnClick={form.handleSubmit}>
            Send Message
          </Button>
          {showSpinner ? <Spinner /> : null}
        </FormGroup>
      </Form>
    </Wrapper>
  )
}

export default ContactForm
