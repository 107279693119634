import React from 'react'

const Logo = () => {
  return (
    <svg
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 91 91'
      width='100%'
      height='100%'
    >
      <path
        className='cls-1'
        fill='#ffcc09'
        d='M86,5V86H5V5H86m5-5H0V91H91V0Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M16.12,44.4,19.7,25.5h4.76L28,44.4H24.13l-.63-4H20.72l-.65,4Zm4.6-4H23.5l-1.4-9.87Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M29.71,44.4V25.5h5.2a11.66,11.66,0,0,1,3.32.42,4,4,0,0,1,2.18,1.5,5.22,5.22,0,0,1,.78,3.07A10.82,10.82,0,0,1,41,32.57a3.94,3.94,0,0,1-.69,1.57,3.75,3.75,0,0,1-1.44,1.11l2.59,9.15H37.15l-2.07-8.47H33.89V44.4Zm4.18-10.87h1.16a2.54,2.54,0,0,0,1.38-.32,1.64,1.64,0,0,0,.69-.91,4.68,4.68,0,0,0,.2-1.44A3.43,3.43,0,0,0,36.87,29a1.8,1.8,0,0,0-1.63-.67H33.89Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M45.23,44.4V28.6H42.38V25.5h9.87v3.1H49.43V44.4Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M53.91,44.4V25.5h8.5v2.84H58.09V33h3.29v2.89H58.09v5.65h4.36V44.4Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M69.76,44.68a6.41,6.41,0,0,1-3.4-.78,4.2,4.2,0,0,1-1.76-2.19,9.84,9.84,0,0,1-.51-3.33V31.56a10,10,0,0,1,.51-3.37A4.14,4.14,0,0,1,66.36,26,8,8,0,0,1,73,25.93a3.86,3.86,0,0,1,1.67,1.92,7.88,7.88,0,0,1,.49,2.85v1.63H71.11V30.44a9.2,9.2,0,0,0-.06-1.07,1.54,1.54,0,0,0-.33-.85,1.18,1.18,0,0,0-1-.34,1.25,1.25,0,0,0-1,.35,1.71,1.71,0,0,0-.4.88,7.8,7.8,0,0,0-.08,1.1v8.9a5.83,5.83,0,0,0,.11,1.15,1.6,1.6,0,0,0,.42.84,1.24,1.24,0,0,0,.92.32,1.14,1.14,0,0,0,.93-.34A1.63,1.63,0,0,0,71,40.5a7.78,7.78,0,0,0,.07-1.09v-2H75.2v1.64a8.13,8.13,0,0,1-.48,2.88,3.94,3.94,0,0,1-1.66,2A6.21,6.21,0,0,1,69.76,44.68Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M20.55,69.29a4.49,4.49,0,0,1-2.46-.61A3.76,3.76,0,0,1,16.62,67,7.58,7.58,0,0,1,16,64.39L17.1,64a10.71,10.71,0,0,0,.5,2.19,3,3,0,0,0,1,1.48,3.14,3.14,0,0,0,1.93.53,3.24,3.24,0,0,0,2.21-.7,3,3,0,0,0,.8-2.34A4,4,0,0,0,22.94,63a12,12,0,0,0-1.81-2.14l-3.45-3.43a6.06,6.06,0,0,1-1.26-1.77,4.54,4.54,0,0,1-.4-1.83,3.61,3.61,0,0,1,1.16-2.87,4.45,4.45,0,0,1,3-1,5.52,5.52,0,0,1,1.73.25,3.31,3.31,0,0,1,1.34.79,3.78,3.78,0,0,1,.9,1.46,9,9,0,0,1,.42,2.24l-1.08.26a7.57,7.57,0,0,0-.44-2.22,2.67,2.67,0,0,0-1-1.32A3.34,3.34,0,0,0,20.2,51a3.5,3.5,0,0,0-2.15.65,2.49,2.49,0,0,0-.86,2.1,3.58,3.58,0,0,0,.29,1.4,5.12,5.12,0,0,0,1.09,1.47L22,60a14.34,14.34,0,0,1,2,2.4,5,5,0,0,1,.83,2.79,4.58,4.58,0,0,1-.54,2.34,3.34,3.34,0,0,1-1.49,1.37A5.27,5.27,0,0,1,20.55,69.29Z'
      />
      <path
        className='cls-2'
        d='M29.82,69.06V51.18H26v-1h8.73v1H31.08V69.06Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M41.46,69.31a5.51,5.51,0,0,1-2.87-.62A3.3,3.3,0,0,1,37.16,67a6.41,6.41,0,0,1-.41-2.39V50.15H38v14.5a5.65,5.65,0,0,0,.29,1.85,2.49,2.49,0,0,0,1.05,1.3,4.05,4.05,0,0,0,2.11.46,3.82,3.82,0,0,0,2-.48,2.67,2.67,0,0,0,1.08-1.29,4.9,4.9,0,0,0,.33-1.84V50.15h1.23V64.62A6,6,0,0,1,45.71,67a3.45,3.45,0,0,1-1.46,1.68A5.25,5.25,0,0,1,41.46,69.31Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M49.42,69.06V50.15h4.13a5.15,5.15,0,0,1,2.94.72,3.85,3.85,0,0,1,1.47,2,8.33,8.33,0,0,1,.42,2.76v7.84A8.86,8.86,0,0,1,58,66.31a3.89,3.89,0,0,1-1.43,2,4.82,4.82,0,0,1-2.86.74Zm1.26-1H53.6a3.44,3.44,0,0,0,2.29-.65,3.14,3.14,0,0,0,1-1.74,11.62,11.62,0,0,0,.23-2.37V55.68a8.91,8.91,0,0,0-.28-2.39,2.73,2.73,0,0,0-1-1.56,3.75,3.75,0,0,0-2.22-.55H50.68Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M61.51,69.06V50.15h1.26V69.06Z'
      />
      <path
        className='cls-2'
        fill='#3d3838'
        d='M70.57,69.31a4.82,4.82,0,0,1-2.86-.73,3.75,3.75,0,0,1-1.41-2,9.5,9.5,0,0,1-.4-2.88V55.43a8.82,8.82,0,0,1,.42-2.85,3.77,3.77,0,0,1,1.43-1.94,6,6,0,0,1,5.63,0,3.73,3.73,0,0,1,1.44,1.94,8.82,8.82,0,0,1,.42,2.85v8.28a9.71,9.71,0,0,1-.4,2.87,3.87,3.87,0,0,1-1.41,2A4.82,4.82,0,0,1,70.57,69.31Zm0-1a3.56,3.56,0,0,0,2.09-.53,2.7,2.7,0,0,0,1-1.51A8,8,0,0,0,74,64V55.24A7.88,7.88,0,0,0,73.67,53a2.66,2.66,0,0,0-1-1.48,4.41,4.41,0,0,0-4.19,0,2.69,2.69,0,0,0-1,1.48,8.25,8.25,0,0,0-.28,2.24V64a8.44,8.44,0,0,0,.28,2.25,2.77,2.77,0,0,0,1,1.51A3.56,3.56,0,0,0,70.57,68.26Z'
      />
    </svg>
  )
}

export default Logo
