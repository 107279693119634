import styled from 'styled-components'
import {
  oswaldBold,
  oswaldLight,
  colorDark,
  breakPointMd,
  breakPointSm,
  modalSliderMd
} from '../../../styled/helper.styled'

export const ProjectInfoWrapper = styled.div``
export const Heading = styled.h3`
  ${oswaldBold};
  color: black;
  font-size: 22px;
`

export const Paragraph = styled.p`
  ${oswaldLight};
  font-size: 18px;
  color: ${colorDark};
`

export const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`

export const Item = styled.li`
  text-align: left;
  width: 20%;
  @media only screen and (max-width: ${modalSliderMd}) {
    width: 25%;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: ${breakPointMd}) {
    width: 33.33%;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    width: 50%;
  }
`
