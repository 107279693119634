import 'react-app-polyfill/ie11'
import smoothscroll from 'smoothscroll-polyfill'
import 'core-js/es/set'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

smoothscroll.polyfill()

ReactDOM.render(<App />, document.getElementById('root'))
