import styled from 'styled-components'
import {
  colorDark,
  oswaldBold,
  robotoLight,
  colorLight,
  colorGrayBackground,
  modalContactsLg,
  modalContactsMd,
  modalContactsSm,
  modalContactsHeightLg1,
  modalContactsHeightLg2,
  modalContactsHeightMd1,
  modalContactsHeightMd2,
  modalContactsHeightMd3,
  modalContactsHeightSm1,
  modalContactsHeightSm2,
  modalContactsHeightSm3,
  modalContactsHeightSm4
} from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: ${colorGrayBackground};
  z-index: 100;
  min-height: -webkit-fill-available;
  @media only screen and (max-height: ${modalContactsHeightSm3}) and (max-width: ${modalContactsLg}) {
    overflow-y: scroll;
  }
`

export const ImageContainer = styled.div`
  width: 700px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: url(${({ desctopImage }) => desctopImage});
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: ${modalContactsLg}) {
    width: 100%;
    position: relative;
    height: 200px;
  }
  @media only screen and (max-height: ${modalContactsHeightLg2}) and (max-width: ${modalContactsLg}) {
    background: url(${({ mobileImage }) => mobileImage});
    background-position: center;
    background-size: cover;
    height: 100px;
  }
`
export const LogoContainer = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  right: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;

  @media only screen and (max-width: ${modalContactsLg}) {
    right: auto;
    transform: translate(-50%, 0);
    top: auto;
    bottom: -45px;
    left: 50%;
  }

  @media only screen and (max-height: ${modalContactsHeightSm1}) and (max-width: ${modalContactsLg}) {
    width: 70px;
    height: 70px;
    bottom: -35px;
  }
`

export const ContentWrapper = styled.div`
  width: calc(100% - 700px);
  height: 100%;
  float: right;
  @media only screen and (max-width: ${modalContactsLg}) {
    width: 100%;
    float: none;
    height: calc(100% - 200px);
  }
  @media only screen and (max-width: ${modalContactsMd}) {
    height: calc(100% - 100px);
  }
  @media only screen and (max-height: ${modalContactsHeightSm4}) and (min-width: ${modalContactsLg}) {
    overflow-y: scroll;
  }
  @media only screen and (max-width: ${modalContactsMd}) {
    width: 100%
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const ContentContainer = styled.div`
  width: 648px;
  position: relative;
  margin: 0 auto;
  padding-top: 140px;
  height: 100%;

  @media only screen and (max-height: ${modalContactsHeightMd2}) and (min-width: ${modalContactsLg}) {
    padding-top: 50px;
  }

  @media only screen and (max-height: ${modalContactsHeightMd3}) and (min-width: ${modalContactsLg}) {
    padding-top: 20px;
  }

  @media only screen and (max-height: ${modalContactsHeightLg1}) and (max-width: ${modalContactsLg}) {
    padding-top: 80px;
  }

  @media only screen and (max-height: ${modalContactsHeightSm1}) and (max-width: ${modalContactsLg}) {
    padding-top: 50px;
  }

  @media only screen and (max-width: ${modalContactsMd}) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`

export const Heading = styled.h2`
  text-transform: uppercase;
  color: ${colorDark};
  font-size: 44px;
  ${oswaldBold};
  text-align: center;
  margin-bottom: 38px;

  @media only screen and (max-height: ${modalContactsHeightSm2}) and (min-width: ${modalContactsLg}) {
    font-size: 25px;
    margin-bottom: 30px;
  }

  @media only screen and (max-height: ${modalContactsHeightSm1}) and (max-width: ${modalContactsLg}) {
    font-size: 25px;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: ${modalContactsSm}) {
    font-size: 25px;
    margin-bottom: 30px;
  }
`

export const Text = styled.p`
  ${robotoLight};
  color: ${colorLight};
  text-align: center;
  font-size: 14px;
  padding: 0 88px;
  margin-bottom: 96px;

  @media only screen and (max-height: ${modalContactsHeightSm2}) and (min-width: ${modalContactsLg}) {
    display: none;
  }

  @media only screen and (max-height: ${modalContactsHeightMd1}) and (max-width: ${modalContactsLg}) {
    display: none;
  }

  @media only screen and (max-width: ${modalContactsMd}) {
    display: none;
  }
`
