import styled, { keyframes } from 'styled-components'
import {
  oswaldBold,
  colorSliderGray,
  robotoLight,
  oswaldLight,
  modalContactsLg,
  colorPrimary,
  modalContactsHeightSm1
} from '../../../styled/helper.styled'

export const Wrapper = styled.div``

export const Form = styled.form``

export const FormGroup = styled.div`
  position: relative;
  height: 50px;
  margin-bottom: 50px;
  &:nth-child(3) {
    height: 80px;
  }
  &:nth-child(4) {
    margin-top 60px;
  }
  @media only screen and (max-height: ${modalContactsHeightSm1}) and (max-width: ${modalContactsLg}) {
    margin-bottom: 25px;
  }
`
export const Label = styled.label`
  ${oswaldBold};
  text-transform: uppercase;
  position: absolute;
  left: 0;
  transition: 0.2s ease;
  width: 100%;
  pointer-events: none;
  top: ${({ active }) => (active ? '-20px' : '10px')};
  font-size: ${({ active }) => (active ? '12px' : '14px')};
`
export const Field = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colorSliderGray};
  width: 100%;
  height: 100%;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    top: -20px;
    font-size: 12px;
  }
`

export const TextArea = styled.textarea`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${colorSliderGray};
  width: 100%;
  height: 100%;
  font-size: 16px;
  padding-top: 10px;
  resize: none;
  ${robotoLight};
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    top: -20px;
    font-size: 12px;
  }
`

export const ErrorMessage = styled.span`
  color: red;
  position: absolute;
  right: 0;
  text-transform: none;
  ${oswaldLight};
`
// Spinner animation
// Its a half circle that spins and transforms into a CHECK MARK (rotate 1120deg)
const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg) }
  99% { transform: rotate(1120deg); border-color: ${colorPrimary} }
  100% {
    border-radius: 0;
    transform: rotate(1120deg);
    width: 10px;
    border-color: green;
  }
`
export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border-right: 2px solid ${colorPrimary};
  border-bottom: 2px solid ${colorPrimary};
  border-radius: 50%;
  transform: rotate(0deg);
  position: absolute;
  right: 10px;
  bottom: 30px;
  animation: ${spinnerAnimation} 1s ease forwards;
`
