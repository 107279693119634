import React, { useContext } from 'react'
import Contacts from '../../shared/Contacts/Contacts'
import Button from '../../shared/Button/Button'
import {
  Wrapper,
  LogoContainer,
  ImageContainer,
  Container,
  Content,
  ContentContainer,
  Heading,
  Text,
  ContactsContainer
} from './style'
import contactUsImage from '../../../assets/images/contact-us-image.jpg'
import { ModalContactFormContext } from '../../../context/modalContactFormContext'
import Socials from '../../shared/Socials/Socials'
import Logo from '../../svg/Logo'

function WorkTogether () {
  const { openContactModal } = useContext(ModalContactFormContext)
  return (
    <Wrapper id='contact-us'>
      <ImageContainer image={contactUsImage}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </ImageContainer>

      <Container id='contact-us-2'>
        <Content>
          <ContentContainer>
            <Heading>Let’s work together</Heading>
            <Text>
              Donec rutrum congue leo eget malesuada. Curabitur non nulla sit
              amet nisl tempus convallis quis ac lectus. Vestibulum ante ipsum
              primis in faucibus orci luctus et ultrices posuere cubilia Curae;
              Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit
              amet ligula.
            </Text>
            <Button funcOnClick={openContactModal}>Contact us</Button>
          </ContentContainer>

          <ContactsContainer>
            <Contacts />
            <Socials />
          </ContactsContainer>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default WorkTogether
