import React from 'react'
import { ModalContactFormContext } from '../../context/modalContactFormContext'
import { ModalSliderContext } from '../../context/modalSliderContext'
import useModalSlider from '../hooks/useModalSlider'
import useModalContactForm from '../hooks/useModalContactForm'
import projects from '../../data/projectsData'

const AppContext = ({ children }) => {
  const {
    nextSlide,
    prevSlide,
    isStart,
    isEnd,
    currentSlide,
    slides,
    setCurrentSlide,
    mouseDown,
    mouseUp,
    isModalSliderOpen,
    openModalSlider,
    closeModalSlider,
    addEscFuncModalSlider,
    removeEscFuncModalSlider,
    onArrowKeyDown,
    removeOnArrowKeyDown,
    activeCategory,
    setActiveCategory,
    filteredProjects,
    categories,
    setSlides,
    filteredGallery
  } = useModalSlider(projects)

  const {
    isContactModalOpen,
    openContactModal,
    closeContactModal,
    addEscFuncModalContacts,
    removeEscFuncModalContacts
  } = useModalContactForm()

  return (
    <ModalSliderContext.Provider
      value={{
        nextSlide,
        prevSlide,
        isStart,
        isEnd,
        currentSlide,
        slides,
        setCurrentSlide,
        mouseDown,
        mouseUp,
        openModalSlider,
        closeModalSlider,
        addEscFuncModalSlider,
        removeEscFuncModalSlider,
        onArrowKeyDown,
        removeOnArrowKeyDown,
        isModalSliderOpen,
        activeCategory,
        setActiveCategory,
        filteredProjects,
        categories,
        setSlides,
        filteredGallery
      }}
    >
      <ModalContactFormContext.Provider
        value={{
          isContactModalOpen,
          openContactModal,
          closeContactModal,
          addEscFuncModalContacts,
          removeEscFuncModalContacts
        }}
      >
        {children}
      </ModalContactFormContext.Provider>
    </ModalSliderContext.Provider>
  )
}

export default AppContext
