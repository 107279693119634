import { useState } from 'react'

const useSlider = (gallery, isModal, setProjectIndex) => {
  const [slides, setSlides] = useState(gallery)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [dragStart, setDragStart] = useState(0)
  const [sliderRef, setSliderRef] = useState(null)

  const nextSlide = () => {
    setCurrentSlide(oldValue => (isEnd() ? 0 : ++oldValue))
    if (setProjectIndex) {
      setProjectIndex(isEnd() ? 0 : currentSlide + 1)
    }
  }
  const prevSlide = () => {
    setCurrentSlide(oldValue => (isStart() ? slides.length - 1 : --oldValue))
    if (setProjectIndex) {
      setProjectIndex(isStart() ? slides.length - 1 : currentSlide - 1)
    }
  }

  const isStart = () => currentSlide === 0
  const isEnd = () => currentSlide + 1 === slides.length

  const mouseDown = e => {
    const xStart = e.type === 'touchstart' ? e.changedTouches[0].pageX : e.pageX
    setDragStart(xStart)
  }

  const mouseUp = e => {
    const xEnd = e.type === 'touchend' ? e.changedTouches[0].pageX : e.pageX
    const next = dragStart > xEnd + 100
    const prev = dragStart + 100 < xEnd
    if (next) {
      nextSlide()
    }
    if (prev) {
      prevSlide()
    }
  }

  const isInViewPort = () => {
    if (isModal) {
      return false
    }
    if (!sliderRef) {
      return true
    }
    const { current } = sliderRef
    const sliderStartPos = current.offsetTop
    const sliderEndPos = sliderStartPos + current.offsetHeight
    const windowStartPos = window.pageYOffset
    const windowEndPos = windowStartPos + window.innerHeight

    const isInViewPort =
      (sliderStartPos > windowStartPos && sliderStartPos < windowEndPos) ||
      (sliderEndPos > windowStartPos && sliderEndPos < windowEndPos)

    return isInViewPort
  }

  const onKeyPress = e => {
    if (isInViewPort()) {
      if (e.keyCode === 37) {
        prevSlide()
      }
      if (e.keyCode === 39) {
        nextSlide()
      }
    }
  }

  const onArrowKeyDown = sliderRef => {
    if (!isModal) {
      setSliderRef(sliderRef)
    }
    document.addEventListener('keydown', onKeyPress, false)
  }

  const removeOnArrowKeyDown = () =>
    document.removeEventListener('keydown', onKeyPress, false)

  return {
    nextSlide,
    prevSlide,
    isStart,
    isEnd,
    currentSlide,
    slides,
    setCurrentSlide,
    mouseDown,
    mouseUp,
    onArrowKeyDown,
    removeOnArrowKeyDown,
    setSlides
  }
}

export default useSlider
