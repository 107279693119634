import styled from 'styled-components'
import {
  colorGrayBackground,
  container,
  oswaldBold,
  robotoLight,
  colorLight,
  containerBreakPoint,
  breakPointSm
} from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  margin-top: 100px;
  position: relative;
  background: ${colorGrayBackground};
  height: 455px;

  @media only screen and (max-width: ${containerBreakPoint}) {
    height: auto;
    text-align: center;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    margin-top: 70px;
  }
`
export const LogoContainer = styled.div`
  position: absolute;
  width: 90px;
  height: 90px;
  right: -45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  @media only screen and (max-width: ${containerBreakPoint}) {
    bottom: -45px;
    left: 50%;
    right: auto;
    top: auto;
    transform: translate(-50%, 0);
  }
`

export const ImageContainer = styled.div`
  width: 50%;
  position: absolute;
  left: 0;
  top: 0;
  height: 455px;
  background: url(${({ image }) => image});
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: ${containerBreakPoint}) {
    position: relative;
    display: block;
    height: 400px;
    width: 100%;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    height: 178px;
  }
`
export const Container = styled.div`
  ${container};
  height: 100%;
  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 100%;
    padding: 10px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 45px;
  padding-bottom: 20px;
  width: 50%;
  float: right;
  padding-left: 100px;
  height: 100%;

  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 100%;
    padding: 0px;
    padding-bottom: 25px;
    float: none;
  }
`
export const ContentContainer = styled.div`
  @media only screen and (max-width: ${containerBreakPoint}) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
`

export const Heading = styled.h1`
  ${oswaldBold}
  font-size: 44px;
  color: $color-dark;
  text-transform: uppercase;
`
export const Text = styled.p`
  ${robotoLight}
  line-height: 20px;
  font-size: 14px;
  margin-top: 28px;
  margin-bottom: 53px;
  color: ${colorLight};
`

export const ContactsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${breakPointSm}) {
    flex-direction: column;
    div:nth-child(1) {
      margin-bottom: 10px;
    }
  }
`
