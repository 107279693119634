import React from 'react'

const Envelope = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M31.789 14.4c.332 0 .6.269.6.6 0 .331-.268.6-.6.6h-8.397c-.332 0-.6-.269-.6-.6 0-.331.268-.6.6-.6h8.397zm3.315 2.739L17.136 35.113c-.589.589-1.362.884-2.136.884-.773 0-1.546-.295-2.135-.884L1.571 23.815c-.235-.234-.235-.613 0-.848.234-.234.614-.234.848 0l11.294 11.297c.71.711 1.865.71 2.574 0l17.969-17.973c.709-.71.709-1.866 0-2.576L22.968 2.425c-.234-.235-.234-.615 0-.849.234-.234.614-.234.848 0l11.288 11.29c1.177 1.179 1.177 3.095 0 4.273zM15.595 23.4v8.4c0 .331-.268.6-.6.6-.332 0-.6-.269-.6-.6v-8.4c0-.331.268-.6.6-.6.332 0 .6.269.6.6zm2.999-1.8H.6c-.332 0-.6-.269-.6-.601l.003-.057c.013-.787.325-1.525.883-2.084L18.855.885c.57-.572 1.328-.886 2.134-.886h.004c.332 0 .6.269.6.6V18.6c0 1.654-1.346 3-2.999 3zm1.799-20.301c-.257.089-.492.236-.69.434L1.735 19.707c-.2.198-.347.435-.435.692h17.294c.992 0 1.799-.807 1.799-1.799V1.299zM.6 27.6h2.399c.332 0 .6.268.6.6 0 .331-.268.6-.6.6H.6c-.332 0-.6-.269-.6-.6 0-.332.268-.6.6-.6zm0 3.6h4.798c.332 0 .6.268.6.6 0 .331-.268.6-.6.6H.6c-.332 0-.6-.269-.6-.6 0-.332.268-.6.6-.6zm0 3.6h8.397c.331 0 .6.269.6.6 0 .331-.268.6-.6.6H.6c-.332 0-.6-.269-.6-.601 0-.33.268-.599.6-.599z'
      />
    </svg>
  )
}

export default Envelope
