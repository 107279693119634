import styled, { css } from 'styled-components'
import {
  oswaldLight,
  colorExtraLight,
  breakPointMd,
  breakPointSm
} from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  ${oswaldLight};
  color: ${colorExtraLight};
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  ${({ center }) =>
    center &&
    css`
      justify-content: center;
    `};
  @media only screen and (max-width: ${breakPointMd}) {
    justify-content: space-around;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    width: 100%;
    flex-wrap: wrap;
  }
`

export const ContactsItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 27px;

  @media only screen and (max-width: ${breakPointMd}) {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    margin-bottom: 10px;
    margin-right: 0;
  }
`
export const Link = styled.a`
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  svg {
    margin-right: 6px;
  }
`
