import React from 'react'
import { ProjectInfoWrapper, List, Item, Heading, Paragraph } from './style'

const ProjectInfo = ({ project }) => {
  const { location, status, client, category, size } = project
  return (
    <ProjectInfoWrapper>
      <List>
        <Item>
          <Heading>Location</Heading>
          <Paragraph>{location}</Paragraph>
        </Item>
        <Item>
          <Heading>Status</Heading>
          <Paragraph>{status}</Paragraph>
        </Item>
        <Item>
          <Heading>Client</Heading>
          <Paragraph>{client}</Paragraph>
        </Item>
        <Item>
          <Heading>Type</Heading>
          <Paragraph>{category}</Paragraph>
        </Item>
        <Item>
          <Heading>Size</Heading>
          <Paragraph>{size}</Paragraph>
        </Item>
      </List>
    </ProjectInfoWrapper>
  )
}

export default ProjectInfo
