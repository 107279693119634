import React from 'react'
import { Wrapper, ArtecTextWrapper, ConceptikTextWrapper } from './style'
import ConceptikLogo from '../../svg/ConceptikLogo'

function Footer () {
  return (
    <Wrapper>
      <ArtecTextWrapper>
        Copyright by ArtecStudio LLC. All rights reserved.
      </ArtecTextWrapper>
      <ConceptikTextWrapper href='https://conceptik.com/' target='_blank'>
        Design & Development by
        <ConceptikLogo />
      </ConceptikTextWrapper>
    </Wrapper>
  )
}

export default Footer
