import styled from 'styled-components'
import { oswaldLight, colorDark } from '../../../styled/helper.styled'
import {
  containerBreakPoint,
  containerTabletBreakPoint,
  breakPointMd
} from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  ${oswaldLight};
  padding: 10px 40px;
  line-height: 1.67;
  color: #aaaaaa;
  background: ${colorDark};
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  @media only screen and (max-width: ${containerBreakPoint}) {
    padding: 10px 20px;
  }
  @media only screen and (max-width: ${breakPointMd}) {
    flex-direction: column;
  }
`
export const ArtecTextWrapper = styled.div``
export const ConceptikTextWrapper = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #aaaaaa;
  & svg {
    width: 132px;
    height: 28px;
    margin-left: 10px;
  }

  @media only screen and (max-width: ${containerTabletBreakPoint}) {
    & svg {
      width: 110px;
      height: 23px;
    }
  }
`
