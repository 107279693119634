import styled from 'styled-components'
import {
  oswaldLight,
  oswaldBold,
  colorDark,
  breakPointSm,
  containerBreakPoint,
  portfolioTableBreakPointLg,
  portfolioTableBreakPointMd,
  containerTablet,
  containerTabletBreakPoint
} from '../../../styled/helper.styled'

export const PortfolioTableWrapper = styled.div``

export const Container = styled.div`
  ${containerTablet};
  @media only screen and (max-width: ${containerTabletBreakPoint}) {
    padding-left: 0;
    padding-right: 0;
  }
`

export const PortfolioList = styled.ul`
  list-style-type: none;
  padding-bottom: 35px;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 990px;
    margin: auto;
  }
  @media only screen and (max-width: ${portfolioTableBreakPointLg}) {
    width: 690px;
  }
  @media only screen and (max-width: ${portfolioTableBreakPointMd}) {
    justify-content: center;
    width: 360px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    padding-bottom: 25px;
    width: auto;
  }
`

export const PorfolioItem = styled.li`
  height: 360px;
  width: 360px;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 30px;
  transition: 0.2s ease;
  margin-left: ${({ isMiddle }) => (isMiddle ? `30px` : `0`)};
  margin-right: ${({ isMiddle }) => (isMiddle ? `30px` : `0`)};
  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 310px;
    height: 310px;
  }
  @media only screen and (max-width: ${portfolioTableBreakPointLg}) {
    width: 330px;
    height: 330px;
    margin-left: 0px;
    margin-right: 0px;
    &:nth-child(odd) {
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: ${portfolioTableBreakPointMd}) {
    width: 360px;
    height: 360px;
    margin-left: 0px;
    margin-right: 0px !important;
  }
`

export const MenuCategories = styled.ul`
  list-style-type: none;
  display: flex;
  margin-top: 30px;
  position: relative;
  user-select: none;
  @media only screen and (max-width: ${breakPointSm}) {
    overflow-x: scroll;
    margin-left: -15px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const Category = styled.li`
  ${({ active }) => (active ? oswaldBold : `${oswaldLight}`)};
  text-transform: uppercase;
  margin-right: 22px;
  cursor: pointer;
  font-size: 14px;
  color: ${colorDark};
  -webkit-tap-highlight-color: transparent;
  @media only screen and (max-width: ${breakPointSm}) {
    padding: 5px 15px;
    margin-right: 0;
  }
`
