import React, { useState } from 'react'
import SectionHeading from '../../shared/SectionHeading/SectionHeading'
import { LatestProjectsWrapper, Container } from './style'
import Slider from '../../shared/Slider/Slider'
import { latestProjectsImagesArray } from '../../../assets/images/latest-projects/latestProjectsArray'
import ProjectInfo from '../../shared/ProjectInfo/ProjectInfo'
import Divider from '../../shared/Divider/Divider'
import latestProjectsData from '../../../data/latestProjectsData'

function LatestProject () {
  const [proejctIndex, setProjectIndex] = useState(0)
  return (
    <LatestProjectsWrapper id='latest-projects'>
      <Container>
        <SectionHeading>Latest Projects</SectionHeading>
      </Container>

      <Slider
        gallery={latestProjectsImagesArray}
        theme='dark'
        setProjectIndex={setProjectIndex}
        latestProject
      />

      <Container>
        <Divider />
        <ProjectInfo project={latestProjectsData[proejctIndex]} />
      </Container>
    </LatestProjectsWrapper>
  )
}

export default LatestProject
