import useSlider from './useSlider'
import useModal from './useModal'
import { useState } from 'react'
import { projectCategories } from '../../constants/constants'

const useModalSlider = projects => {
  const [activeCategory, setActiveCategory] = useState(projectCategories.ALL)
  const categories = [...new Set(projects.map(project => project.category))]
  categories.unshift(projectCategories.ALL)

  const filteredProjects = () =>
    activeCategory === projectCategories.ALL
      ? projects
      : projects.filter(project => project.category === activeCategory)

  const filteredGallery = () => filteredProjects().map(project => project.image)

  const {
    nextSlide,
    prevSlide,
    isStart,
    isEnd,
    currentSlide,
    slides,
    setCurrentSlide,
    mouseDown,
    mouseUp,
    onArrowKeyDown,
    removeOnArrowKeyDown,
    setSlides
  } = useSlider(filteredGallery())

  const {
    isModalOpen,
    openModal,
    closeModal,
    addEscFunc,
    removeEscFunc
  } = useModal()

  return {
    nextSlide,
    prevSlide,
    isStart,
    isEnd,
    currentSlide,
    slides,
    setCurrentSlide,
    mouseDown,
    mouseUp,
    isModalSliderOpen: isModalOpen,
    openModalSlider: openModal,
    closeModalSlider: closeModal,
    addEscFuncModalSlider: addEscFunc,
    removeEscFuncModalSlider: removeEscFunc,
    onArrowKeyDown,
    removeOnArrowKeyDown,
    projects,
    activeCategory,
    setActiveCategory,
    filteredProjects,
    categories,
    setSlides,
    filteredGallery
  }
}

export default useModalSlider
