import React, { useEffect, useContext } from 'react'
import {
  Wrapper,
  ImageContainer,
  LogoContainer,
  ContentWrapper,
  ContentContainer,
  Heading,
  Text
} from './style'
import contactUsImageDesc from '../../../assets/images/desktop/modal-contact-us-image.png'
import contactUsImageMobile from '../../../assets/images/desktop/contact-us-image.jpg'
import ContactForm from '../ContactForm/ContactForm'
import { ModalContactFormContext } from '../../../context/modalContactFormContext'
import CloseIcon from '../CloseIcon/CloseIcon'
import Logo from '../../svg/Logo'

const ModalContactForm = ({ isOpen }) => {
  const {
    closeContactModal,
    addEscFuncModalContacts,
    removeEscFuncModalContacts
  } = useContext(ModalContactFormContext)

  useEffect(() => {
    addEscFuncModalContacts()
    return removeEscFuncModalContacts
  }, [addEscFuncModalContacts, removeEscFuncModalContacts])

  return (
    <Wrapper isOpen={isOpen}>
      <CloseIcon isBackground={false} closeFunc={closeContactModal} />

      <ImageContainer
        image={contactUsImageDesc}
        desctopImage={contactUsImageDesc}
        mobileImage={contactUsImageMobile}
      >
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </ImageContainer>

      <ContentWrapper>
        <ContentContainer>
          <Heading>Let’s work together</Heading>
          <Text>
            We believe that every great design begins with a conversation.
            Contact us to start the discussion around your residential,
            commercial, or marine space.
          </Text>

          <ContactForm />
        </ContentContainer>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ModalContactForm
