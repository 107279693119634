import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'

const useForm = () => {
  const [showSpinner, setShowSpinner] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, 'Min 3 charachters expected')
        .required('Required'),
      message: Yup.string()
        .min(10, 'Min 10 charachters expected')
        .required('Required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Required')
    }),
    onSubmit: (values, { resetForm }) => {
      // Spinner animation happens on rendering, so we remove it and add it again to trigger the animation
      // Re animate the spinner if user submit his form twice
      setShowSpinner(false)
      setShowSpinner(true)
      // Wait for the spinner animation to finish
      setTimeout(() => resetForm(), 1000)
    }
  })

  return [formik, showSpinner]
}

export default useForm
