import React from 'react'

const Twitter = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10.447.746C5.035.746.649 5.055.649 10.373.649 15.691 5.035 20 10.447 20c5.411 0 9.797-4.309 9.797-9.627 0-5.318-4.386-9.627-9.797-9.627zm5.13 7.904c.005.096.007.194.007.293 0 2.993-2.319 6.446-6.56 6.446-1.303 0-2.514-.375-3.535-1.018.181.021.364.031.551.031 1.08 0 2.074-.361 2.863-.969-1.009-.018-1.861-.673-2.153-1.572.14.026.284.039.433.039.21 0 .414-.027.607-.079-1.054-.208-1.849-1.125-1.849-2.223V9.57c.312.17.667.272 1.045.284-.619-.406-1.026-1.099-1.026-1.887 0-.414.114-.804.313-1.138C7.409 8.2 9.109 9.101 11.025 9.196c-.039-.167-.059-.339-.059-.516 0-1.251 1.032-2.265 2.305-2.265.662 0 1.262.274 1.683.715.524-.102 1.018-.29 1.464-.549-.172.529-.538.972-1.013 1.252.466-.054.911-.176 1.324-.356-.311.454-.702.854-1.152 1.173z'
      />
    </svg>
  )
}

export default Twitter
