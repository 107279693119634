import React from 'react'

const CloseX = props => {
  return (
    <svg
      id='Capa_1'
      enableBackground='new 0 0 413.348 413.348'
      height='23'
      viewBox='0 0 413.348 413.348'
      width='23'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='currentColor'
        d='m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z'
      />
    </svg>
  )
}

export default CloseX
