import styled, { keyframes } from 'styled-components'
import { fadeIn, fadeOut } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`
const fadeOutAnimation = keyframes`${fadeOut}`

export const Fade = styled.div`
  & > div {
    animation: ${({ state }) =>
        state !== 'exiting' && state !== 'exited'
          ? fadeInAnimation
          : fadeOutAnimation}
      0.3s forwards;
  }
`
