import { useState } from 'react'
import { HeaderHeight } from '../../constants/constants'

const useHeaderAnimation = () => {
  const [showHeader, setShowHeader] = useState(false)

  const addScrollEvenet = () => {
    window.addEventListener('scroll', () => onScroll())
  }

  const removeScrollEvent = () => {
    window.removeEventListener('scroll', onScroll)
  }

  const onScroll = () => {
    setShowHeader(window.pageYOffset > 2 * HeaderHeight.desktop)
  }

  return [addScrollEvenet, removeScrollEvent, showHeader]
}

export default useHeaderAnimation
