import React from 'react'
import { ButtonWrapper, ButtonLink } from './style'

const Button = ({ funcOnClick, children, center }) => {
  const clicked = e => {
    e.preventDefault()
    if (funcOnClick) {
      funcOnClick(e)
    }
  }

  return (
    <ButtonWrapper center={center}>
      <ButtonLink href='/#' onClick={clicked}>
        {children}
      </ButtonLink>
    </ButtonWrapper>
  )
}

export default Button
