import styled, { css } from 'styled-components'
import {
  oswaldLight,
  container,
  containerBreakPoint,
  headerModalBreakPointMd,
  breakPointSm
} from '../../../styled/helper.styled'

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 10px 0;
  width: 100%;
  transition: 0.2s ease;
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      transform: translateY(-200%);
      background: white;
      z-index: 5;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    `}
    ${({ isFixed, showHeader }) =>
      isFixed &&
      showHeader &&
      css`
        transform: translateY(0);
      `}
    ${({ isFixed, showHeader }) =>
      isFixed &&
      !showHeader &&
      css`
        transform: translateY(-200%);
      `}
  
`

export const Container = styled.div`
  ${container};
  display: flex;

  @media only screen and (max-width: ${containerBreakPoint}) {
    flex-direction: column;
  }
`

export const HeaderContacts = styled.div`
  display: flex;
  flex: 1;
  @media only screen and (max-width: ${containerBreakPoint}) {
    flex: none;
  }
  @media only screen and (max-width: ${headerModalBreakPointMd}) {
    & > div:nth-child(2) {
      display: none;
    }
  }
`

export const LogoContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-right: 25px;
  min-width: 91px;
  width: 91px;
  height: 91px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  @media only screen and (max-width: ${containerBreakPoint}) {
    width: 60px;
    height: 60px;
    position: relative;
    left: -15px;
  }
  @media only screen and (max-width: ${breakPointSm}) {
    width: 40px;
    height: 40px;
    left: -25px;
  }
`

export const TogglerContainer = styled.div`
  justify-content: flex-end;
  display: none;
  margin-top: 10px;
  -webkit-tap-highlight-color: transparent;

  @media only screen and (max-width: ${containerBreakPoint}) {
    display: flex;
    align-items: center;
    flex: 1;
  }
`

export const MobileToggler = styled.div`
  width: 30px;
  height: 20px;
  position: relative;
  cursor: pointer;
`
const togglerLinesStyle = css`
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: black;
  left: 0;
  transition: 0.2s ease;
`
export const TogglerTopLine = styled.div`
  ${togglerLinesStyle};
  ${({ mobileNavOpened }) =>
    mobileNavOpened ? 'top: 50%; transform: rotate(45deg);' : 'top: 0;'}
`

export const TogglerMidLine = styled.div`
  ${togglerLinesStyle};
  top: 50%;
  transform: translateY(-50%);
  ${({ mobileNavOpened }) =>
    mobileNavOpened &&
    css`
      opacity: 0;
    `}
`

export const TogglerBotLine = styled.div`
  ${togglerLinesStyle};
  ${({ mobileNavOpened }) =>
    mobileNavOpened ? 'bottom: 40%; transform: rotate(-45deg)' : 'bottom: 0;'}
`

export const NavigationContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: ${containerBreakPoint}) {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${containerBreakPoint}) {
    display: block;
    flex: none;
    overflow: hidden;
    transition: 0.2s ease;
    ${({ mobileNavOpened }) =>
      mobileNavOpened
        ? 'height: 170px; margin: 10px 0;'
        : 'margin: 0; height: 0'}
  }
`

export const Navigation = styled.ul`
  list-style-type: none;
  display: flex;

  @media only screen and (max-width: ${containerBreakPoint}) {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
`

export const NavigationItem = styled.li`
  margin-right: 25px;

  @media only screen and (max-width: ${containerBreakPoint}) {
    margin: 8px 0;
  }
`

export const NavigationLink = styled.a`
  outline: none;
  ${oswaldLight};
  text-decoration: none;
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  -webkit-tap-highlight-color: transparent;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
