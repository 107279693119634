import React from 'react'

const LinkedIn = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' {...props}>
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M10.745.746C5.334.746.947 5.056.947 10.373.947 15.689 5.334 20 10.745 20c5.411 0 9.797-4.311 9.797-9.627 0-5.318-4.386-9.627-9.797-9.627zm-2.449 14.29h-2.45V6.612h2.45v8.424zm-1.149-8.94c-.635 0-1.148-.505-1.148-1.129 0-.623.514-1.129 1.148-1.129.634.002 1.149.508 1.149 1.129 0 .624-.515 1.129-1.149 1.129zm9.721 8.94h-2.449V9.828c0-.61-.177-1.037-.94-1.037-1.265 0-1.509 1.037-1.509 1.037v5.208H9.52V6.612h2.45v.805c.35-.263 1.224-.803 2.449-.803.794 0 2.449.467 2.449 3.287v5.135z'
      />
    </svg>
  )
}

export default LinkedIn
