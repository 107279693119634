import React, { useEffect, useRef, useContext } from 'react'
import { ModalSliderContext } from '../../../context/modalSliderContext'
import { ModalContactFormContext } from '../../../context/modalContactFormContext'

import {
  SliderWrapper,
  ImageContainer,
  Image,
  Caption,
  Heading,
  Controls,
  SliderIndexes,
  Index
} from './style'
import PrevNextBtns from '../PrevNextBtns/PrevNextBtns'
import useSlider from '../../hooks/useSlider'

const Slider = ({
  gallery,
  fullWidth,
  theme,
  setProjectIndex,
  latestProject
}) => {
  const { isModalSliderOpen } = useContext(ModalSliderContext)
  const { isContactModalOpen } = useContext(ModalContactFormContext)

  const {
    nextSlide,
    prevSlide,
    isStart,
    isEnd,
    currentSlide,
    slides,
    setCurrentSlide,
    mouseDown,
    mouseUp,
    onArrowKeyDown,
    removeOnArrowKeyDown
  } = useSlider(
    gallery,
    isModalSliderOpen || isContactModalOpen,
    setProjectIndex
  )

  const sliderRef = useRef(null)

  useEffect(() => {
    onArrowKeyDown(sliderRef)
    return removeOnArrowKeyDown
  }, [onArrowKeyDown, removeOnArrowKeyDown])

  return (
    <SliderWrapper ref={sliderRef}>
      <ImageContainer
        currentSlide={currentSlide}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onTouchStart={mouseDown}
        onTouchEnd={mouseUp}
        latestProject={latestProject}
      >
        {slides.map((slide, index) => (
          <Image img={slide} fullWidth={fullWidth} key={index}>
            {fullWidth ? (
              <Caption>
                <Heading>RE-IMAGINE YOUR SPACE</Heading>
                <br />
                <Heading>Architecture of any complexity</Heading>
              </Caption>
            ) : null}
          </Image>
        ))}
      </ImageContainer>

      <Controls>
        <SliderIndexes>
          {slides.map((_, index) => (
            <Index
              key={index}
              active={index === currentSlide}
              onClick={() => setCurrentSlide(index)}
            >
              {index + 1 < 10 ? '0' + (index + 1) : index + 1}
            </Index>
          ))}
        </SliderIndexes>

        <PrevNextBtns
          prev={prevSlide}
          next={nextSlide}
          isEnd={isEnd()}
          isStart={isStart()}
          theme={theme}
        />
      </Controls>
    </SliderWrapper>
  )
}

export default Slider
