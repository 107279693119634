import projectImg1 from './project-img-1.jpg'
import projectImg2 from './project-img-2.jpg'
import projectImg3 from './project-img-3.jpg'
import projectImg4 from './project-img-4.jpg'
import projectImg5 from './project-img-5.jpg'
import projectImg6 from './project-img-6.jpg'
import projectImg7 from './project-img-7.jpg'
import projectImg8 from './project-img-8.jpg'
import projectImg9 from './project-img-9.jpg'
import projectImg10 from './project-img-10.jpg'
import projectImg11 from './project-img-11.jpg'
import projectImg12 from './project-img-12.jpg'
import projectImg13 from './project-img-13.jpg'
import projectImg14 from './project-img-14.jpg'
import projectImg15 from './project-img-15.jpg'

import projectImg1Thumb from './thumbs/layer-1.jpg'
import projectImg2Thumb from './thumbs/layer-2.jpg'
import projectImg3Thumb from './thumbs/layer-3.jpg'
import projectImg4Thumb from './thumbs/layer-4.jpg'
import projectImg5Thumb from './thumbs/layer-5.jpg'
import projectImg6Thumb from './thumbs/layer-6.jpg'
import projectImg7Thumb from './thumbs/layer-7.jpg'
import projectImg8Thumb from './thumbs/layer-8.jpg'
import projectImg9Thumb from './thumbs/layer-9.jpg'
import projectImg10Thumb from './thumbs/layer-10.jpg'
import projectImg11Thumb from './thumbs/layer-11.jpg'
import projectImg12Thumb from './thumbs/layer-12.jpg'
import projectImg13Thumb from './thumbs/layer-13.jpg'
import projectImg14Thumb from './thumbs/layer-14.jpg'
import projectImg15Thumb from './thumbs/layer-15.jpg'

export const ProjectsImagesArray = [
  projectImg1,
  projectImg2,
  projectImg3,
  projectImg4,
  projectImg5,
  projectImg6,
  projectImg7,
  projectImg8,
  projectImg9,
  projectImg10,
  projectImg11,
  projectImg12,
  projectImg13,
  projectImg14,
  projectImg15
]

export const ProjectsImagesThumbArray = [
  projectImg1Thumb,
  projectImg2Thumb,
  projectImg3Thumb,
  projectImg4Thumb,
  projectImg5Thumb,
  projectImg6Thumb,
  projectImg7Thumb,
  projectImg8Thumb,
  projectImg9Thumb,
  projectImg10Thumb,
  projectImg11Thumb,
  projectImg12Thumb,
  projectImg13Thumb,
  projectImg14Thumb,
  projectImg15Thumb
]
