import styled from 'styled-components'
import { colorPrimary, oswaldBold } from '../../../styled/helper.styled'

export const Wrapper = styled.div`
  background: ${colorPrimary};
  display: inline-block;
  padding: 5px 30px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    height: 5px;
    background: ${colorPrimary};
    width: 1000%;
  }
`
export const SectionHeadingText = styled.h2`
  ${oswaldBold};
  font-size: 26px;
  text-transform: uppercase;
`
