import React from 'react'
import { Wrapper, Button } from './style'
import ArrowLeftIcon from '../../svg/ArrowLeftIcon'
import ArrowRightIcon from '../../svg/ArrowRightIcon'

const PrevNextBtns = ({ prev, next, theme, isStart, isEnd }) => {
  return (
    <Wrapper>
      <Button onClick={prev} active={!isStart} theme={theme}>
        <ArrowLeftIcon />
        PREV
      </Button>
      /
      <Button active={!isEnd} onClick={next} theme={theme}>
        NEXT
        <ArrowRightIcon />
      </Button>
    </Wrapper>
  )
}

export default PrevNextBtns
