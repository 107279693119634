import { latestProjectsImagesArray } from '../assets/images/desktop/latest-projects/latestProjectsImages'

const projects = Object.freeze([
  {
    id: 0,
    image: latestProjectsImagesArray[0],
    category: 'Offices',
    title: 'Vel pharetra vel turpis',
    location: 'Varna, Bulgaria',
    status: 'Build in 2014',
    size: '26,000 sq/m',
    client: 'White House Group'
  },
  {
    id: 1,
    image: latestProjectsImagesArray[1],
    category: 'Public',
    title: 'Eget arcu dictum varius',
    location: 'Sofia, Bulgaria',
    status: 'Build in 2016',
    size: '46,000 sq/m',
    client: 'Black House Group'
  },
  {
    id: 2,
    image: latestProjectsImagesArray[2],
    category: 'Hotels',
    title: 'Ullamcorper malesuada proin libero',
    location: 'Burgas, Bulgaria',
    status: 'Build in 2018',
    size: '50,000 sq/m',
    client: 'White Group'
  }
])

export default projects
